<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="File Status"
      @click.native.stop
    >
      <v-select
        v-model="fileStatusFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="value"
        multiple
        placeholder="Select from list"
        :options="fileStatusOptions"
        :clearable="false"
        :reduce="o => o.key"
      />
    </b-form-group>
    <b-form-group
      label="Payment Status"
      @click.native.stop
    >
      <v-select
        v-model="paymentStatusFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="value"
        multiple
        placeholder="Select from list"
        :options="paymentStatusOptions"
        :clearable="false"
        :reduce="o => o.key"
      />
    </b-form-group>
    <b-form-group
      label="Product Description"
      @click.native.stop
    >
      <v-select
        v-model="productFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="value"
        multiple
        placeholder="Select from list"
        :options="productOptions"
        :clearable="false"
        :reduce="o => o.key"
      />
    </b-form-group>
    <b-form-group
      label="Booking Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="bookingDateFilterValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Confirm Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="confirmDateFilterValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Show Files From">
      <b-form-checkbox
        v-for="option in customerOptions"
        :key="option.value"
        v-model="customerTypeFilterValue"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.label }}
      </b-form-checkbox>
    </b-form-group>
    <div class="d-flex justify-content-between gap-3 mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1 w-50"
        @click="clearFilter"
      >
        Clear All
      </b-button>
      <b-button
        variant="primary"
        class="w-50"
        @click="applyFilter"
      >
        Apply Filter
      </b-button>
    </div>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    fileStatusFilter: {
      type: [Array, null],
      default: () => [],
    },
    paymentStatusFilter: {
      type: [Array, null],
      default: () => [],
    },
    productFilter: {
      type: [Array, null],
      default: () => [],
    },
    customerTypeFilter: {
      type: [Array, null],
      default: () => [],
    },
    bookingDateFilter: {
      type: [String, null],
      default: null,
    },
    confirmDateFilter: {
      type: [String, null],
      default: null,
    },
    fileStatusOptions: {
      type: Array,
      default: () => [],
    },
    paymentStatusOptions: {
      type: Array,
      default: () => [],
    },
    productOptions: {
      type: Array,
      default: () => [],
    },
    customerOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bookingDateFilterValue: this.bookingDateFilter,
      confirmDateFilterValue: this.confirmDateFilter,
      fileStatusFilterValue: JSON.parse(JSON.stringify(this.fileStatusFilter)) || [],
      productFilterValue: JSON.parse(JSON.stringify(this.productFilter)) || [],
      customerTypeFilterValue: JSON.parse(JSON.stringify(this.customerTypeFilter)) || [],
      paymentStatusFilterValue: JSON.parse(JSON.stringify(this.paymentStatusFilter)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    bookingDateFilter: {
      immediate: true,
      handler(val) {
        this.bookingDateFilterValue = val
      },
    },
    confirmDateFilter: {
      immediate: true,
      handler(val) {
        this.confirmDateFilterValue = val
      },
    },
    fileStatusFilter: {
      immediate: true,
      handler(val) {
        this.fileStatusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    customerTypeFilter: {
      immediate: true,
      handler(val) {
        this.customerTypeFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    productFilter: {
      immediate: true,
      handler(val) {
        this.productFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    paymentStatusFilter: {
      immediate: true,
      handler(val) {
        this.paymentStatusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    showAgainDropdown() {
      if (this.bookingDateFilterValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:bookingDateFilter', this.bookingDateFilterValue)
      this.$emit('update:confirmDateFilter', this.confirmDateFilterValue)
      this.$emit('update:fileStatusFilter', this.fileStatusFilterValue)
      this.$emit('update:paymentStatusFilter', this.paymentStatusFilterValue)
      this.$emit('update:productFilter', this.productFilterValue)
      this.$emit('update:customerTypeFilter', this.customerTypeFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.bookingDateFilterValue = ''
      this.confirmDateFilterValue = ''
      this.fileStatusFilterValue = []
      this.paymentStatusFilterValue = []
      this.productFilterValue = []
      this.customerTypeFilterValue = []
      this.$emit('update:bookingDateFilter', this.bookingDateFilterValue)
      this.$emit('update:confirmDateFilter', this.confirmDateFilterValue)
      this.$emit('update:fileStatusFilter', this.fileStatusFilterValue)
      this.$emit('update:paymentStatusFilter', this.paymentStatusFilterValue)
      this.$emit('update:productFilter', this.productFilterValue)
      this.$emit('update:customerTypeFilter', this.customerTypeFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
